import Bowser from "bowser";

class KindWebEventsService {
  environments = {
    development:
      "https://api-gateway.dev.kind.app/activity-tracking/gui-event/unauthenticated",
    staging:
      "https://api-gateway.staging.kind.app/activity-tracking/gui-event/unauthenticated",
    production:
      "https://gateway.api.engaging.care/activity-tracking/gui-event/unauthenticated",
  };

  platform = JSON.stringify(
    Bowser.getParser(window.navigator.userAgent).parsePlatform() || ""
  );
  os = JSON.stringify(
    Bowser.getParser(window.navigator.userAgent).parseOS() || ""
  );
  browser = JSON.stringify(
    Bowser.getParser(window.navigator.userAgent).parseBrowser() || ""
  );

  postData = async (url = "", data = {}) => {
    await fetch(url, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      // credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    });
  };

  selectedEnvironment = this.environments.production;

  constructor(environment) {
    if (environment) {
      this.selectedEnvironment = this.environments[environment];
    }
  }

  sendNavigationEvent = () => {
    console.log("sendNavigationEvent method fired");
  };

  sendClickEvent = (screenName, eventName) => {
    if (
      (!screenName || !eventName) &&
      this.selectedEnvironment === this.environments.development
    ) {
      throw new Error("Parameters missing for sendClickEvent");
    } else if (!screenName || !eventName) {
      // Silently fail when not in DEV
      return false;
    }

    const payload = {
      event: "CLICK",
      screen: screenName,
      itemId: eventName,
      deviceType: this.platform,
      deviceModel: this.os,
      deviceVersion: this.browser,
      userAgent: navigator.userAgent,
      sourceUrl: window.location.href,
    };

    this.postData(this.selectedEnvironment, payload);
  };
}

export default KindWebEventsService;
